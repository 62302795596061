import { LoginFields, TLoginEmailFormData } from "../types";

type TEmailLoginData = {
  email: string;
  password: string;
  recaptcha: string;
};

const mapFormToEmailLoginData = (formData: TLoginEmailFormData, recaptcha: string): TEmailLoginData => ({
  email: formData[LoginFields.EMAIL],
  password: formData[LoginFields.PASSWORD],
  recaptcha,
});
export default mapFormToEmailLoginData;
