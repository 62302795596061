import getEnv from "@utils/getEnv";

const getFbAppId = (locale?: string): string | undefined => {
  if (locale === "lt") {
    return getEnv("NEXT_PUBLIC_FACEBOOK_APP_ID_LT");
  }
  if (locale === "lv") {
    return getEnv("NEXT_PUBLIC_FACEBOOK_APP_ID_LV");
  }
  if (locale === "ee") {
    return getEnv("NEXT_PUBLIC_FACEBOOK_APP_ID_EE");
  }
  return undefined;
};
export default getFbAppId;
