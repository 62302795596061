import fetchApi, { isFetchError } from "@lib/api-fetcher/fetchApi";
import { API_ROUTES, FLAGS } from "@utils/constants";
import { reportError, reportMessage } from "@utils/errorReporting";

import validateLoginResponse from "../models/loginResponse";
import type { TLoginEmailFormData, TLoginFacebookFormData, TLoginResult } from "../types";
import mapFormToEmailLoginData from "../utils/mapFormToEmailLoginData";
import mapFormToFacebookLoginData from "../utils/mapFormToFacebookLoginDat";
import mapLoginErrorsToErrorMessages from "../utils/mapLoginErrorsToErrorMessages";
import mapLoginResponseToResult from "../utils/mapLoginResponseToResult";

const submitLogin = async (
  isFacebook: boolean,
  formData: TLoginEmailFormData | TLoginFacebookFormData,
  recaptchaToken: string
): Promise<TLoginResult> => {
  try {
    const url = isFacebook ? API_ROUTES.AUTH_FACEBOOK : API_ROUTES.AUTH;

    const data = isFacebook
      ? mapFormToFacebookLoginData(formData as TLoginFacebookFormData, recaptchaToken)
      : mapFormToEmailLoginData(formData as TLoginEmailFormData, recaptchaToken);

    const response = await fetchApi({
      url,
      method: "POST",
      data,
    });

    const loginResponse = validateLoginResponse(response.data);

    const result = mapLoginResponseToResult(loginResponse);

    if (!result) {
      reportMessage("Invalid response returned from login", { data: loginResponse });
      return { isSuccess: false, notVerifiedFlag: false };
    }
    return result;
  } catch (err) {
    if (isFetchError(err)) {
      const { errorMessage, fieldErrors } = mapLoginErrorsToErrorMessages(err);
      if (err.response?.data?.detail?.flag === FLAGS.NOT_VERIFIED_FLAG) {
        return { isSuccess: false, notVerifiedFlag: true, errorMessage, fieldErrors };
      }
      if (err.response?.status === 422) {
        return { isSuccess: false, notVerifiedFlag: false, errorMessage, fieldErrors };
      }
    }
    reportError(err, { module: "login" });
    return { isSuccess: false, notVerifiedFlag: false };
  }
};
export default submitLogin;
