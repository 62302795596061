import { FC } from "react";

import { HStack, Box, useMultiStyleConfig } from "@chakra-ui/react";
import { TFunction, useTranslation } from "next-i18next";

import { Typography } from "@components/common";

import { PasswordComplexity } from "./types";

const getPasswordStrengthLabels = (t: TFunction) => ({
  [PasswordComplexity.Low]: t("common-password_input-strength_weak"),
  [PasswordComplexity.Mid]: t("common-password_input-strength_average"),
  [PasswordComplexity.High]: t("common-password_input-strength_strong"),
});

const passwordComplexityDistanceToRight = {
  [PasswordComplexity.Low]: "66%",
  [PasswordComplexity.Mid]: "33%",
  [PasswordComplexity.High]: "0",
};

const passwordComplexityLeftColor = {
  [PasswordComplexity.Low]: "red.lead",
  [PasswordComplexity.Mid]: "orange.warm",
  [PasswordComplexity.High]: "green.warm",
};

const PasswordStrengthIndicator: FC<{ complexity: PasswordComplexity }> = ({ complexity }) => {
  const { t } = useTranslation();
  const style = useMultiStyleConfig("PasswordInput", {});

  return (
    <HStack spacing="1rem" mt="0.375rem" my="0.188rem">
      <Typography type="small" sx={style.strengthLabel}>
        {getPasswordStrengthLabels(t)[complexity]}
      </Typography>
      <Box sx={style.indicatorContainer}>
        <Box
          sx={style.indicator}
          right={passwordComplexityDistanceToRight[complexity]}
          backgroundColor={passwordComplexityLeftColor[complexity]}
        />
      </Box>
    </HStack>
  );
};

export default PasswordStrengthIndicator;
