import type { TFetchError } from "@lib/api-fetcher/types";

import { LoginFields, TLoginResult } from "../types";

const mapLoginErrorsToErrorMessages = (
  errorResponse: TFetchError
): Pick<TLoginResult, "errorMessage" | "fieldErrors"> => {
  if (!errorResponse.response) {
    return {};
  }
  const { data } = errorResponse.response;
  const errorMessage = data?.title;
  const errorDetails = data?.detail;

  const fieldErrors = { [LoginFields.EMAIL]: errorDetails?.email, [LoginFields.PASSWORD]: errorDetails?.password };

  return { errorMessage, fieldErrors };
};
export default mapLoginErrorsToErrorMessages;
