import { FC } from "react";

import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import { FullWidthVStack } from "@components/common";
import Link from "@components/Link";
import { Desktop } from "@lib/media";
import responsiveStyle from "@lib/theme/responsiveStyle";
import { ROUTES } from "@utils/constants";

import type { TLoginFormProps } from "../types";
import EmailLogin from "./EmailLogin";
import FacebookLogin from "./FacebookLogin";

const LoginForm: FC<TLoginFormProps> = ({ isMinimal, fieldErrors, onEmailLoginSubmit, onFacebookLoginSubmit }) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig("Login", {});

  // Using full page loader to handle both email and fb login loading cases the same way
  return (
    <FullWidthVStack spacing="1rem" mb="1rem">
      <EmailLogin isMinimal={isMinimal} fieldErrors={fieldErrors} onSubmit={onEmailLoginSubmit} />
      {!isMinimal && (
        <>
          <Desktop>
            <Box sx={styles.linkContainer} textAlign="center">
              <Link
                variant="red"
                label={t("login-forgot_password")}
                href={ROUTES.FORGOT_PASSWORD}
                id="login-forgot-password-link-desktop"
                fontSize={responsiveStyle({ mobile: "0.875rem", desktop: "1rem" })}
              />
            </Box>
          </Desktop>
          <Box sx={styles.linkContainer} textAlign="center">
            {t("login-dont_have_an_account")}{" "}
            <Link
              variant="red"
              label={t("login-create_an_account")}
              href={ROUTES.REGISTER}
              id="login-register-link"
              fontSize={responsiveStyle({ mobile: "0.875rem", desktop: "1rem" })}
            />
          </Box>
          <FacebookLogin onSubmit={onFacebookLoginSubmit} />
        </>
      )}
    </FullWidthVStack>
  );
};
export default LoginForm;
