import { FC } from "react";

import { useTranslation } from "next-i18next";

import { IconButton } from "@components/buttons";
import Icon from "@components/Icon";

import { TTogglePreviewProps } from "./types";

const TogglePreviewButton: FC<TTogglePreviewProps> = ({ isVisible, onToggle }) => {
  const { t } = useTranslation();

  return (
    <IconButton
      variant="transparent"
      icon={isVisible ? <Icon icon="secretHide" /> : <Icon icon="secretReveal" />}
      onClick={onToggle}
      name="preview-password"
      title={t(isVisible ? "common-password_input-hide_preview" : "common-password_input-reveal_preview")}
    />
  );
};
export default TogglePreviewButton;
