import { TFunction } from "next-i18next";
import * as yup from "yup";

import { LoginFields } from "../types";

const getFormValidationSchema = (t: TFunction): yup.AnyObjectSchema =>
  yup.object().shape({
    [LoginFields.EMAIL]: yup.string().required(t("login-email_required")),
    [LoginFields.PASSWORD]: yup.string().required(t("login-password_required")),
  });
export default getFormValidationSchema;
