import { FC, useEffect, useMemo } from "react";

import { Box, useMultiStyleConfig } from "@chakra-ui/react";
import { Formik, Form, useFormikContext } from "formik";
import { useTranslation } from "next-i18next";

import { Button } from "@components/buttons";
import { FullWidthVStack, Typography } from "@components/common";
import Link from "@components/Link";
import PasswordInput from "@components/PasswordInput";
import TextInput from "@components/TextInput";
import { Mobile } from "@lib/media";
import responsiveStyle from "@lib/theme/responsiveStyle";
import { ROUTES } from "@utils/constants";

import { LoginFields, TEmailLoginProps } from "../types";
import getFormValidationSchema from "../utils/getFormValidationSchema";

const INITIAL_FORM_VALUES = { [LoginFields.EMAIL]: "", [LoginFields.PASSWORD]: "" };

const EmailLoginForm: FC<Pick<TEmailLoginProps, "fieldErrors" | "isMinimal">> = ({ fieldErrors, isMinimal }) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig("Login", {});

  const { setErrors } = useFormikContext();

  useEffect(() => {
    if (fieldErrors) {
      setErrors(fieldErrors);
    }
  }, [fieldErrors, setErrors]);

  return (
    <Form id="login-email-form" noValidate>
      <FullWidthVStack spacing="1rem">
        <Typography type="sectionHeading" sx={styles.title}>
          {t("login-sign_in")}
        </Typography>
        <Box width={responsiveStyle({ mobile: "100%", desktop: "22.25rem" })} alignSelf="center">
          <FullWidthVStack spacing="1rem">
            <Box>
              <TextInput name={LoginFields.EMAIL} label={t("login-email")} />
            </Box>
            <Box>
              <PasswordInput name={LoginFields.PASSWORD} label={t("login-password")} hasPreview />
            </Box>
          </FullWidthVStack>
        </Box>
        {!isMinimal && (
          <Mobile>
            <Box sx={styles.linkContainer} textAlign="right">
              <Link
                variant="red"
                label={t("login-forgot_password")}
                href={ROUTES.FORGOT_PASSWORD}
                id="login-forgot-password-link-mobile"
                fontSize={responsiveStyle({ mobile: "0.875rem", desktop: "1rem" })}
              />
            </Box>
          </Mobile>
        )}
        <Box py="0.5rem" textAlign="center">
          <Button
            type="submit"
            label={t("login-sign_in")}
            width={responsiveStyle({ mobile: "100%", desktop: "auto" })}
            id="login-sign-in-button"
          />
        </Box>
      </FullWidthVStack>
    </Form>
  );
};

const EmailLogin: FC<TEmailLoginProps> = ({ onSubmit, ...rest }) => {
  const { t } = useTranslation();

  const validationSchema = useMemo(() => getFormValidationSchema(t), [t]);

  return (
    <Formik initialValues={INITIAL_FORM_VALUES} validationSchema={validationSchema} onSubmit={onSubmit}>
      {() => <EmailLoginForm {...rest} />}
    </Formik>
  );
};

export default EmailLogin;
