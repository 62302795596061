import * as yup from "yup";
import type { Asserts } from "yup";

// Response from /auth or /auth/facebook validation

const loginSchema = yup
  .object()
  .shape({
    token: yup.string().required(),
    refreshToken: yup.string().required(),
    userType: yup.string().oneOf(["ad-hoc", "contracted"]).required(),
    sessionId: yup.string().nullable(),
    contractedUrl: yup.string().nullable(),
    hasInvalidDefaultAddresses: yup.bool(),
    isPasswordExpired: yup.boolean().nullable(),
    email: yup.string().nullable(),
  })
  .defined();

export interface ILoginResponse extends Asserts<typeof loginSchema> {}

const validateLoginResponse = (response: any): ILoginResponse => loginSchema.validateSync(response);

export default validateLoginResponse;
