import { TLoginFacebookFormData } from "../types";

type TFacebookLoginData = {
  accessToken: string;
  recaptcha: string;
};

const mapFormToFacebookLoginData = (
  { accessToken }: TLoginFacebookFormData,
  recaptcha: string
): TFacebookLoginData => ({
  accessToken,
  recaptcha,
});
export default mapFormToFacebookLoginData;
