import { FC } from "react";

import { Box, CSSObject, Spinner, useMultiStyleConfig } from "@chakra-ui/react";

import type { TLoaderProps } from "./types";

const Loader: FC<TLoaderProps & { sx?: CSSObject; overlayStyles?: CSSObject, children?: any }> = ({
  isLoading,
  sx,
  overlayStyles,
  children,
}) => {
  const styles = useMultiStyleConfig("Loader", {});
  return (
    <Box sx={{ ...styles.container, ...sx }}>
      {children}
      {isLoading && (
        <Box sx={{ ...styles.overlay, ...overlayStyles }}>
          <Spinner />
        </Box>
      )}
    </Box>
  );
};
export default Loader;
