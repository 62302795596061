import { ILoginResponse } from "../models/loginResponse";
import { TLoginResult } from "../types";

const mapLoginResponseToResult = ({
  userType,
  token,
  refreshToken,
  sessionId,
  contractedUrl,
  isPasswordExpired,
  hasInvalidDefaultAddresses,
  email,
}: ILoginResponse): TLoginResult | null => {
  if (userType === "contracted") {
    if (!sessionId || !contractedUrl) {
      return null;
    }
    return {
      isSuccess: true,
      notVerifiedFlag: false,
      contractedUser: { sessionId, redirectUrl: contractedUrl },
    };
  }

  if (!token || !refreshToken) {
    return null;
  }
  return {
    isSuccess: true,
    notVerifiedFlag: false,
    authData: { token, refreshToken, isPasswordExpired, hasInvalidDefaultAddresses, email },
  };
};
export default mapLoginResponseToResult;
