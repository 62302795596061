import { FC } from "react";

import Loader from "@components/Loader";

import useLogin from "../hooks/useLogin";
import type { TLoginProps } from "../types";
import LoginForm from "./LoginForm";

const Login: FC<TLoginProps> = ({ isMinimal }) => {
  const { isLoading, fieldErrors, handleEmailLogin, handleFacebookLogin } = useLogin();

  // Using full page loader to handle both email and fb login loading cases the same way
  return (
    <Loader isLoading={isLoading}>
      <LoginForm
        fieldErrors={fieldErrors}
        isMinimal={isMinimal}
        onEmailLoginSubmit={handleEmailLogin}
        onFacebookLoginSubmit={handleFacebookLogin}
      />
    </Loader>
  );
};
export default Login;
