import fetchApi from "@lib/api-fetcher/fetchApi";
import { API_ROUTES } from "@utils/constants";
import { reportError } from "@utils/errorReporting";

import { TVerificationEmailResult } from "../types";

const sendVerificationEmail = async (email: string): Promise<TVerificationEmailResult> => {
  try {
    const url = API_ROUTES.AUTH_SEND_VERIFICATION_EMAIL;
    await fetchApi({
      url,
      method: "POST",
      data: { email },
    });

    return { isSuccess: true };
  } catch (err) {
    reportError(err, { module: "login" });
    return { isSuccess: false };
  }
};
export default sendVerificationEmail;
