import { FC } from "react";
import FacebookLoginButton from "react-facebook-login";

import { Box, Flex, useMultiStyleConfig } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";

import { Divider } from "@components/common";
import Icon from "@components/Icon";
import { useMessage } from "@components/Message";
import { useRouter } from "@lib/router";

import { TFacebookLoginProps } from "../types";
import getFbAppId from "../utils/getFbAppId";

const FacebookLogin: FC<TFacebookLoginProps> = ({ onSubmit }) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig("Login", {});
  const setMessage = useMessage();

  const { defaultLocale } = useRouter();

  const fbAppId = getFbAppId(defaultLocale);

  if (!fbAppId) {
    return null;
  }

  const handleFailure = () => {
    setMessage({
      message: t("login-general_error"),
      props: {
        variant: "error",
        icon: <Icon icon="warning" size="1.45rem" />,
        canClose: true,
        padding: "0.45rem 0.75rem",
      },
      zone: "login",
    });
  };

  const handleResponse = ({ accessToken }) => {
    if (!accessToken) {
      handleFailure();
      return;
    }
    onSubmit({ accessToken });
  };

  return (
    <Box>
      <Divider my="0.5rem" />
      <Flex justify="center" sx={styles.facebookLogin} id="facebook-login">
        <FacebookLoginButton
          appId={fbAppId}
          fields="name,email"
          icon={<Icon icon="facebook" size="1.625rem" />}
          callback={handleResponse}
          onFailure={handleFailure}
          cssClass="fb-login-button"
          size="medium"
          textButton={t("login-with_facebook")}
          disableMobileRedirect
        />
      </Flex>
    </Box>
  );
};
export default FacebookLogin;
