const gtmEcommercePusher = (data: any): void => {
  if (typeof window === "undefined") {
    return;
  }

  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(data);
};

export default gtmEcommercePusher;
