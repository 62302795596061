import { TTextInputProps } from "@components/TextInput/types";

export type TPasswordValidationRules = {
  minLength?: number;
  minLowerCaseCount?: number;
  minUpperCaseCount?: number;
  minDigitsCount?: number;
  minSpecialCharsCount?: number;
};

export type TPasswordInputProps = Omit<TTextInputProps, "defaultValue"> & {
  hasPreview?: boolean;
  validation?: TPasswordValidationRules;
  defaultValue?: string;
  value?: string;
};

export type TTogglePreviewProps = {
  isVisible: boolean;
  onToggle: () => void;
};

export enum PasswordComplexity {
  Low,
  Mid,
  High,
}
