import type { TAuthData } from "@modules/auth";

export type TMobileLoginCTAProps = {
  onShowLoginForm: () => void;
  onFacebookLoginSubmit: (data: TLoginFacebookFormData) => void;
};

export type TLoginProps = {
  isMinimal?: boolean;
};

export type TLoginFormProps = {
  isMinimal?: boolean;
  fieldErrors?: TLoginFieldErrors;
  onEmailLoginSubmit: (data: TLoginEmailFormData) => void;
  onFacebookLoginSubmit: (data: TLoginFacebookFormData) => void;
};

export type TFacebookLoginProps = {
  onSubmit: (data: TLoginFacebookFormData) => void;
};

export type TEmailLoginProps = {
  fieldErrors?: TLoginFieldErrors;
  isMinimal?: boolean;
  onSubmit: (values: TLoginEmailFormData) => void;
};

export type TUseLoginReturn = {
  isLoading: boolean;
  fieldErrors?: TLoginFieldErrors;
  handleEmailLogin: (data: TLoginEmailFormData) => Promise<void>;
  handleFacebookLogin: (data: TLoginFacebookFormData) => Promise<void>;
};

export enum LoginFields {
  EMAIL = "email",
  PASSWORD = "password",
}

export type TLoginEmailFormData = {
  [LoginFields.EMAIL]: string;
  [LoginFields.PASSWORD]: string;
};

export type TLoginFacebookFormData = {
  accessToken: string;
};

export type TVerificationEmailResult = {
  isSuccess: boolean;
  errorMessage?: string;
  fieldErrors?: TLoginFieldErrors;
};

export type TLoginResult = {
  isSuccess: boolean;
  notVerifiedFlag: boolean;
  authData?: TAuthData;
  contractedUser?: TContractedUserResult;
  errorMessage?: string;
  fieldErrors?: TLoginFieldErrors;
};

export type TContractedUserResult = {
  sessionId: string;
  redirectUrl: string;
};

export type TLoginFieldErrors = { [field in LoginFields]?: string };
