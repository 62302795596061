import { useCallback, useState } from "react";

import { chakra } from "@chakra-ui/react";
import { Trans, useTranslation } from "next-i18next";
import Router from "next/router";

import Icon from "@components/Icon";
import { useMessage } from "@components/Message";
import useRecaptcha from "@lib/recaptcha/useRecaptcha";
import { useAuth } from "@modules/auth";
import { ROUTES } from "@utils/constants";
import { reportMessage } from "@utils/errorReporting";

import redirectContractedUser from "../services/redirectContractedUser";
import sendVerificationEmail from "../services/sendVerificationEmail";
import submitLogin from "../services/submitLogin";
import { LoginFields, TLoginEmailFormData, TLoginFacebookFormData, TLoginFieldErrors, TUseLoginReturn } from "../types";

const useLogin = (): TUseLoginReturn => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<TLoginFieldErrors>();
  const { setAuthData } = useAuth();
  const setMessage = useMessage();
  const handleRecaptcha = useRecaptcha("login");

  const handleSuccess = useCallback(() => {
    setMessage({
      message: t("resend_verification_email_success"),
      props: {
        variant: "success",
        icon: <Icon icon="success" size="1.45rem" />,
        canClose: true,
        padding: "0.45rem 0.75rem",
      },
      zone: "login",
    });
  }, [t, setMessage]);

  const handleError = useCallback(
    (message?: string) => {
      setMessage({
        message: message || t("login-general_error"),
        props: {
          variant: "error",
          icon: <Icon icon="warning" size="1.45rem" />,
          canClose: true,
          padding: "0.45rem 0.75rem",
        },
        zone: "login",
      });
    },
    [t, setMessage]
  );

  const handleVerificationEmail = useCallback(
    async (email: string) => {
      setMessage({ message: "", props: {}, zone: "login" }); // Clearing previous message
      setIsLoading(true);
      const result = await sendVerificationEmail(email);
      setIsLoading(false);
      if (result.isSuccess) {
        handleSuccess();
      } else {
        handleError(result.errorMessage);
      }
    },
    [setMessage, handleSuccess, handleError]
  );

  const handleNotVerified = useCallback(
    (data: LoginFields.EMAIL) => {
      setMessage({
        message: (
          <Trans
            i18nKey="verify_account_forgot_password"
            components={{
              sendlink: (
                <chakra.a
                  href="#"
                  onClick={() => handleVerificationEmail(data)}
                  color="red.lead"
                  sx={{ _hover: { textDecoration: "underline" } }}
                />
              ),
            }}
          />
        ),
        props: {
          variant: "error",
          icon: <Icon icon="warning" size="1.45rem" />,
          canClose: true,
          padding: "0.45rem 0.75rem",
        },
        zone: "login",
      });
    },
    [setMessage, handleVerificationEmail]
  );

  const handleLogin = useCallback(
    async (isFacebook: boolean, data: TLoginEmailFormData | TLoginFacebookFormData, recaptchaToken: string) => {
      setMessage({ message: "", props: {}, zone: "login" }); // Clearing previous message
      const result = await submitLogin(isFacebook, data, recaptchaToken);

      if (result.notVerifiedFlag && !isFacebook) {
        handleNotVerified(data[LoginFields.EMAIL]);
        setIsLoading(false);
        return result;
      }
      if (!result.isSuccess || (!result.authData && !result.contractedUser)) {
        handleError(result.errorMessage);
        setIsLoading(false);
        return result;
      }

      if (result.contractedUser) {
        redirectContractedUser(result.contractedUser);
        return result;
      }

      if (!result.authData) {
        handleError(result.errorMessage);
        setIsLoading(false);
        return result;
      }

      setAuthData(result.authData);

      if (result.authData.hasInvalidDefaultAddresses) {
        Router.push({ pathname: ROUTES.PROFILE_MY_ADDRESSES });
        return result;
      }

      if (result.authData.isPasswordExpired) {
        Router.push({ pathname: ROUTES.PROFILE_ACCOUNT });
      } else {
        Router.push({ pathname: ROUTES.LANDING });
      }

      return result;
    },

    [setMessage, setAuthData, handleNotVerified, handleError]
  );

  const handleRecaptchaAndLogin = useCallback(
    async (data: TLoginFacebookFormData | TLoginEmailFormData, isFacebook: boolean) => {
      setIsLoading(true);
      const recaptchaToken = await handleRecaptcha();

      if (!recaptchaToken) {
        reportMessage("Recaptcha token not available");
        handleError();
        setIsLoading(false);
        return;
      }
      const result = await handleLogin(isFacebook, data, recaptchaToken);

      if (!result.isSuccess && !isFacebook) {
        setFieldErrors(result.fieldErrors || undefined);
      }
    },
    [handleLogin, handleError, handleRecaptcha]
  );

  const handleEmailLogin = (data: TLoginEmailFormData) => handleRecaptchaAndLogin(data, false);
  const handleFacebookLogin = (data: TLoginFacebookFormData) => handleRecaptchaAndLogin(data, true);

  return { isLoading, fieldErrors, handleEmailLogin, handleFacebookLogin };
};

export default useLogin;
