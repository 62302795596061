import React, { FC, useCallback, useState } from "react";

import { Box } from "@chakra-ui/react";
import { i18n } from "next-i18next";

import { FormHint } from "@components/common";
import TextInput from "@components/TextInput";
import useUpdateValue from "@hooks/useUpdateValue";
import { withCompositeFormikField } from "@lib/withFormikField";

import PasswordStrengthIndicator from "./PasswordStrengthIndicator";
import TogglePreviewButton from "./TogglePreviewButton";
import { PasswordComplexity, TPasswordInputProps } from "./types";
import getPasswordComplexity from "./utils/getPasswordComplexity";

const PasswordInput: FC<TPasswordInputProps> = ({
  hasPreview,
  validation,
  defaultValue,
  value,
  onChange,
  hint,
  ...rest
}) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);
  const [innerValue, updateValue] = useUpdateValue(value || defaultValue || "");
  const passwordComplexity = validation ? getPasswordComplexity(innerValue || "", validation) : null;

  const handleInputChange = useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(evt);
      const { value: inputValue } = evt.target;
      updateValue(inputValue);
    },
    [onChange, updateValue]
  );

  const togglePreviewButton = (
    <TogglePreviewButton isVisible={isPreviewVisible} onToggle={() => setIsPreviewVisible((isVisible) => !isVisible)} />
  );

  /**
   * Since enum has a starting value of 0, we need to explicitly check if passwordComplexity is not null.
   */
  const shouldShowStrengthIndicator = innerValue && passwordComplexity !== null;

  return (
    <Box>
      <TextInput
        rightAddon={!!innerValue && hasPreview && togglePreviewButton}
        rightSpacing="0.25rem"
        rightAddonOffset="2.75rem"
        {...rest}
        isFormik={false}
        type={isPreviewVisible ? "text" : "password"}
        value={innerValue}
        onChange={handleInputChange}
      />
      {shouldShowStrengthIndicator && <PasswordStrengthIndicator complexity={passwordComplexity} />}
      <FormHint hint={hint} />
    </Box>
  );
};

const validate = (password, { validation }: TPasswordInputProps) =>
  password && getPasswordComplexity(password, validation) !== PasswordComplexity.High
    ? i18n?.t("common-password_input-invalid_complexity")
    : undefined;

export default withCompositeFormikField({ validate })(PasswordInput);
