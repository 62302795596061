export enum CURRENCIES {
  EUR = "EUR",
}

export enum STEPS {
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
}

export enum EVENTS {
  CHECKOUT = "checkout",
  PURCHASE = "purchase",
  EMAIL_VERIFIED = "email_verified",
}

export enum DeliveryTypes {
  PudoToPudo = "Locker to locker",
  DoorToDoor = "Address to address",
  DoorToPudo = "Address to locker",
  PudoToDoor = "Locker to address",
}

export enum DeliveryTypeIds {
  ONE = "1",
  TWO = "2",
  THREE = "3",
  FOUR = "4",
}
