import { FC, useEffect } from "react";

import { GetServerSideProps, InferGetServerSidePropsType as Infer } from "next";
import { useTranslation } from "next-i18next";

import { FullWidthVStack } from "@components/common";
import Icon from "@components/Icon";
import { ContextMessage, useMessage } from "@components/Message";
import LayoutHead from "@components/PageHeader/LayoutHead";
import withPropsMiddleware from "@lib/withPropsMiddleware";
import { checkHasAuthCookie } from "@modules/auth";
import { Banners, loadBanners } from "@modules/banners";
import {loadFeatures, useFeatureData} from "@modules/features";
import { EVENTS, gtmEcommercePusher } from "@modules/gtm";
import Layout, { TWithLayout, LayoutContent } from "@modules/layout";
import { Login } from "@modules/login";
import Notifications, { INotifications, loadNotifications } from "@modules/notifications";
import { QUERY_PARAMS, ROUTES } from "@utils/constants";
import getEnv from "@utils/getEnv";
import { IBanners } from "@modules/banners/models/banners";
import UserFeedbackForm from "@modules/userFeedback/components/UserFeedbackForm";
import { FEEDBACK_FORM } from "@modules/features/constants";
import { Desktop } from "@lib/media";

type TWithLocales = {
  defaultLocale: string;
  customLocales: string[];
  customLocale: string; // Change from string? to string
  features: string[] | null;
  banners: IBanners | null;
  notifications: INotifications | null;
  hasRegisterSuccess: boolean;
  hasVerifySuccess: boolean;
  hasVerifyFailure: boolean;
  hasPasswordResetSuccess: boolean;
};

export const getServerSideProps: GetServerSideProps<TWithLocales> = withPropsMiddleware(
  async ({
    locale,
    req: {
      headers: { host },
      cookies,
    },
    query,
  }) => {
    // Redirect to landing page if user is logged in
    if (checkHasAuthCookie(cookies)) {
      return {
        redirect: {
          permanent: false,
          destination: ROUTES.LANDING,
        },
      };
    }

    const notificationsZone = getEnv("NEXT_PUBLIC_NOTIFICATION_LOGIN_ZONE") as string;
    const notificationsPromise = locale ? loadNotifications(notificationsZone, locale, host!) : null;

    const bannersZone = getEnv("NEXT_PUBLIC_BANNERS_LOGIN_ZONE") as string;
    const bannersPromise = locale ? loadBanners(bannersZone, locale, host!) : null;

    const [notifications, banners] = await Promise.all([notificationsPromise, bannersPromise]);

    const hasRegisterSuccess = query?.[QUERY_PARAMS.LOGIN_REGISTER_SUCCESS] === "1";
    const hasVerifySuccess = query?.[QUERY_PARAMS.LOGIN_VERIFY_SUCCESS] === "1";
    const hasVerifyFailure = query?.[QUERY_PARAMS.LOGIN_VERIFY_SUCCESS] === "0";
    const hasPasswordResetSuccess = query?.[QUERY_PARAMS.LOGIN_PASSWORD_RESET_SUCCESS] === "1";
    const features = locale ? await loadFeatures(locale, host) : null;
    return {
      props: {
        notifications,
        banners,
        hasRegisterSuccess,
        hasVerifySuccess,
        hasVerifyFailure,
        hasPasswordResetSuccess,
        features,
        defaultLocale: "",
        customLocales: [],
        customLocale: "",
      },
    };
  }
);

const LoginPage: TWithLayout<FC<Infer<typeof getServerSideProps>>> = ({
  notifications,
  banners,
  hasRegisterSuccess,
  hasVerifySuccess,
  hasVerifyFailure,
  hasPasswordResetSuccess,
}) => {
  const { t } = useTranslation();
  const setMessage = useMessage();
  const features = useFeatureData();

  useEffect(() => {
    if (hasRegisterSuccess) {
      setMessage({
        message: t("login-message-register_success"),
        props: { variant: "success", icon: <Icon icon="success" size="1.875rem" />, isAnimated: false, canClose: true },
        zone: "login",
        autoClearMs: 10000,
      });
    }
    if (hasVerifySuccess) {
      setMessage({
        message: t("login-message-verify_success"),
        props: { variant: "success", icon: <Icon icon="success" size="1.875rem" />, isAnimated: false, canClose: true },
        zone: "login",
        autoClearMs: 10000,
      });
      gtmEcommercePusher({ event: EVENTS.EMAIL_VERIFIED });
    }
    if (hasVerifyFailure) {
      setMessage({
        message: t("login-message-verify_failure"),
        props: { variant: "error", icon: <Icon icon="warning" size="1.875rem" />, isAnimated: false, canClose: true },
        zone: "login",
        autoClearMs: 10000,
      });
    }
    if (hasPasswordResetSuccess) {
      setMessage({
        message: t("login-message-pasword_reset_success"),
        props: { variant: "success", icon: <Icon icon="success" size="1.875rem" />, isAnimated: false, canClose: true },
        zone: "login",
        autoClearMs: 10000,
      });
    }
  }, [hasRegisterSuccess, hasVerifyFailure, hasVerifySuccess, hasPasswordResetSuccess, setMessage, t]);

  return (
    <>
      <LayoutHead title={t("pages-login-title")} description={t("pages-login-description")} />
      <LayoutContent>
        <FullWidthVStack spacing="1rem">
          <Banners bannersData={banners} isDesktopVisible isMobileVisible />
          <Notifications notificationsData={notifications} />
          <ContextMessage messageZone="login" />
          <Login isMinimal={hasRegisterSuccess} />
        </FullWidthVStack>
        { features?.includes(FEEDBACK_FORM) && <><Desktop><UserFeedbackForm /></Desktop></> }
      </LayoutContent>
    </>
  );
};

LoginPage.Layout = <Layout hasRecaptcha hasWebchat={false} hasMobileWebchat={false} />;

export default LoginPage;
