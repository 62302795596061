import { DIGITS_REGEX, LOWERCASE_REGEX, UPPERCASE_REGEX, SPECIAL_CHARS_REGEX } from "@lib/regex";

import { TPasswordValidationRules, PasswordComplexity } from "../types";

const getPasswordComplexity = (password: string, rules?: TPasswordValidationRules): PasswordComplexity => {
  let passedRules = 0;
  let totalRules = 0;
  if (rules?.minLength) {
    passedRules += password.length >= rules.minLength ? 1 : 0;
    totalRules += 1;
  }
  if (rules?.minLowerCaseCount) {
    const lowerCaseCount = password.match(LOWERCASE_REGEX)?.length || 0;
    passedRules += lowerCaseCount >= rules.minLowerCaseCount ? 1 : 0;
    totalRules += 1;
  }
  if (rules?.minUpperCaseCount) {
    const upperCaseCount = password.match(UPPERCASE_REGEX)?.length || 0;
    passedRules += upperCaseCount >= rules.minUpperCaseCount ? 1 : 0;
    totalRules += 1;
  }
  if (rules?.minDigitsCount) {
    const upperCaseCount = password.match(DIGITS_REGEX)?.length || 0;
    passedRules += upperCaseCount >= rules.minDigitsCount ? 1 : 0;
    totalRules += 1;
  }
  if (rules?.minSpecialCharsCount) {
    const specialCharCount = password.match(SPECIAL_CHARS_REGEX)?.length || 0;
    passedRules += specialCharCount >= rules.minSpecialCharsCount ? 1 : 0;
    totalRules += 1;
  }

  const strength = passedRules / totalRules;
  if (strength <= 0.45) {
    return PasswordComplexity.Low;
  }
  if (strength <= 0.85) {
    return PasswordComplexity.Mid;
  }
  return PasswordComplexity.High;
};
export default getPasswordComplexity;
